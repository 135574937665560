import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  userSignInSuccess,
  showAuthMessage
} from 'actions/Auth';
import {LaCon,Passport} from 'constants/Links';

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: ''
    }
  }
  handleKeyDown = (evt)=>{
    if(evt.charCode===13){
      this.logIn();
    }    
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  logIn=()=>{
    this.props.showAuthLoader();
    
    var form = {
      password: this.state.password,
      username: this.state.email,
      grant_type: 'password',
      scope: '',
      client_id: '2',
      client_secret: Passport,
    }
    //console.log(form);
    fetch(`${LaCon}api/logup`,{
      method: 'POST',
      headers: {
          'Content-Type': "application/json"
      },
      body: JSON.stringify(form),
    }).then(res=>res.json())
    .then((result)=>{
      if(result.success){
        sessionStorage.setItem('user_id',result.user);
        //console.log(result)
        sessionStorage.setItem('cargo',result.permissao);
        sessionStorage.setItem('sublocal',result.sublocal)
        sessionStorage.setItem('client_key',result.access_token)
        this.props.userSignInSuccess(result.user);
      }else{
        alert(result.message);
        this.props.hideMessage();
      }
    },(error)=>{
      alert('Houve um erro de ao tentar entrar')
      this.props.hideMessage();
    })
  }

  render() {
    const {
      email,
      password
    } = this.state;
    const {showMessage, loader, alertMessage} = this.props;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content bg-white d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Doe Vidas">
              <img src={require("assets/images/logo.jpg")} alt="Doe Vidas" title="Doe Vidas" style={{height:105}}/>
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Capela do Alto</h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label='E-mail'
                    fullWidth
                    onChange={(event) => this.setState({email: event.target.value})}
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label='Senha'
                    fullWidth
                    onChange={(event) => this.setState({password: event.target.value})}
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    onKeyPress={this.handleKeyDown}
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button onClick={() => this.logIn()/*{
                      this.props.showAuthLoader();
                      this.props.userSignIn({email:'demo@example.com', password:'demo#123'});
                    }*/} variant="contained" color="primary">
                      Entrar
                    </Button>

                  </div>

                </fieldset>
              </form>
            </div>
          </div>

        </div>
        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  showAuthMessage
})(SignIn);
