import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
//import Tour from '../components/Tour/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';

class App extends React.Component {

  render() {
    const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container ${drawerStyle}`}>

        <Sidebar/>
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
            <TopNav styleName="app-top-header"/>}
            <Header/>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
            <TopNav/>}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/relatorio`} component={asyncComponent(() => import('./routes/relatorio/index'))}/>
                <Route path={`${match.url}/plaqueta`} component={asyncComponent(() => import('./routes/plaqueta/PermitirPlaqueta'))}/>
                <Route path={`${match.url}/agendas/nova`} component={asyncComponent(() => import('./routes/pages/novaagenda'))}/>
                <Route path={`${match.url}/saida`} component={asyncComponent(() => import('./routes/pages/saida'))}/>
                <Route path={`${match.url}/falta`} component={asyncComponent(() => import('./routes/falta/falta'))}/>
                <Route path={`${match.url}/ativacao`} component={asyncComponent(() => import('./routes/ativacao/index'))}/>
                <Route path={`${match.url}/apoio/video`} component={asyncComponent(() => import('./routes/apoio/video'))}/>
                <Route path={`${match.url}/apoio/suporte`} component={asyncComponent(() => import('./routes/apoio/suporte'))}/>
                <Route path={`${match.url}/apoio/admin`} component={asyncComponent(() => import('./routes/apoio/admin'))}/>
                <Route path={`${match.url}/diario`} component={asyncComponent(() => import('./routes/confirmacao/ConfirmacaoList'))}/>
                <Route path={`${match.url}/profile`} component={asyncComponent(() => import('./routes/settings/self'))}/>
                <Route path={`${match.url}/doador/:id?`} component={asyncComponent((id) => import('./routes/doador/route'))}/>
                <Route path={`${match.url}/doacao`} component={asyncComponent(() => import('./routes/pages/doacao'))}/>
                <Route path={`${match.url}/usuario`} component={asyncComponent(() => import('./routes/settings/usuario/route'))}/>
                <Route path={`${match.url}/agendab/:id?`} component={asyncComponent((id) => import('./routes/agenda/SearchVacinador'))}/>
                <Route path={`${match.url}/agenda/:id?`} component={asyncComponent((id) => import('./routes/agenda'))}/>
                <Route path={`${match.url}/agendas/cancelado`} component={asyncComponent(() => import('./routes/agenda/cancelados'))}/>
                <Route path={`${match.url}/notificacao`} component={asyncComponent(() => import('./routes/notificacao'))}/>
                <Route path={`${match.url}/estoque`} component={asyncComponent(() => import('./routes/estoque/route'))}/>
                <Route path={`${match.url}/faq`} component={asyncComponent(() => import('./routes/app/faq/route'))}/>
                <Route path={`${match.url}/noticia`} component={asyncComponent(() => import('./routes/app/noticia/route'))}/>
                <Route path={`${match.url}/informativo`} component={asyncComponent(() => import('./routes/app/informativo/route'))}/>
                <Route path={`${match.url}/disponibilidade`} component={asyncComponent(() => import('./routes/app/disponibilidade/route'))}/>
                <Route path={`${match.url}/sublocal`} component={asyncComponent(() => import('./routes/settings/sublocal/route'))}/>
                <Route path={`${match.url}/local`} component={asyncComponent(() => import('./routes/settings/local'))}/>
                <Route path={`${match.url}/dash/:id`} component={asyncComponent((id) => import('./routes/dashboard/local'))}/>
                <Route path={`${match.url}/`} component={asyncComponent(() => import('./routes/dashboard'))}/>
                <Route component={asyncComponent(() => import('components/Error404'))}/>
              </Switch>
            </div>
            <Footer/>
          </main>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({settings}) => {
  const {drawerType, navigationStyle, horizontalNavPosition} = settings;
  return {drawerType, navigationStyle, horizontalNavPosition}
};
export default withRouter(connect(mapStateToProps)(App));